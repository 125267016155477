import React from 'react';
import {Routes,Route} from "react-router-dom";
import Home from '../pages/Home';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';


const AllRoutes = () => {
  return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/terms" element={<TermsAndConditions/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>

        </Routes>
  )
}

export default AllRoutes;