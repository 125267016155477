import {
  Button,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Banner from "../assets/banner.png";
import Banner2 from "../assets/banner2.jpeg";

import Logo from "../assets/alautun-logo.svg";
import IndustryCard from "../components/industryCard";
import Industry1 from "../assets/industry1.svg";
import Industry2 from "../assets/industry2.svg";
import Industry3 from "../assets/industry3.svg";
import Industry4 from "../assets/industry4.svg";
import Industry5 from "../assets/industry5.svg";
import CommonSec from "../components/CommonSec";
import CommonSecImage from "../assets/com-sec1.png";
import CommonSecImage1 from "../assets/com-sec2.png";
import CommonSecImage2 from "../assets/com-sec3.png";
import Frame from "../assets/frame.png";
import Screen from "../assets/phone-screen.png";
import Point from "../components/Point";
import AppStoreLogo from "../assets/app-store.png";
import ShipperQr from "../assets/shipperQr.png";
import ProviderQr from "../assets/providerQr.png";
import PlayStoreLogo from "../assets/google-play.png";
import OrangeLogo from "../assets/alautun-orange-logo.png";
import MenuLinks from "../components/MenuLinks";
import { MdEmail } from "react-icons/md";
import { HiPhone } from "react-icons/hi";
import Industry from "../assets/industry.png";
import PopOver from "../components/PopOver";
import { useTheme } from "@mui/material/styles";
import { FiMenu } from "react-icons/fi";
import { Link as Anchor } from "react-router-dom";
import TopCommon from "../components/TopCommon";

const Home = () => {
  const menuLinkStyles = "!text-[#fff]";
  const orangeButtonStyles =
    "!bg-[#FF8243] !text-[#fff] !px-5 !py-2 !rounded-3xl !font-bold !capitalize";

  const [openSignup, setOpenSignUp] = useState(false);
  const [signupEl, setSignupEl] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [loginEl, setLoginEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClickSignup = (e) => {
    setSignupEl(e.target);
    setOpenSignUp(!openSignup);
    setOpenLogin(false);
  };

  const handleClickLogin = (e) => {
    setLoginEl(e.target);
    setOpenLogin(!openLogin);
    setOpenSignUp(false);
  };

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDownScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Grid>
      <Grid
        className="h-[500px] !bg-cover md:h-[600px] lg:h-[600px]"
        sx={{
          backgroundImage: `url(${Banner2}) !important`,
        }}
      >
        <Grid
          sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          className="w-[100%] h-[100%] px-2 md:px-14"
        >
          <Grid className="flex h-[80px] items-center justify-center gap-4 md:gap-10 lg:justify-between">
            {smScreen || mdScreen || xsScreen || lgDownScreen ? (
              <FiMenu
                className="text-[#fff] !text-[20px] cursor-pointer md:text-[30px]"
                onClick={() => setOpenDrawer(true)}
              />
            ) : (
              <></>
            )}
            <img src={Logo} alt="Logo" className="w-[100px] md:w-[150px]" />
            {lgScreen || xlScreen ? (
              <List className="flex items-start !text-[18px]">
                <ListItem>
                  <Link
                    href="https://shipper.alautun.com"
                    target="_blank"
                    className={menuLinkStyles}
                  >
                    Shippers
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href="https://carrier.alautun.com"
                    target="_blank"
                    className={menuLinkStyles}
                  >
                    Carriers
                  </Link>
                </ListItem>
                {/* <ListItem>
                <Link href="#" className={menuLinkStyles}>
                  Company
                </Link>
              </ListItem>
              <ListItem>
                <Link href="#" className={menuLinkStyles}>
                  Blog
                </Link>
              </ListItem> */}
              </List>
            ) : (
              <></>
            )}
            <List className="flex items-start">
              <ListItem className="!pr-2 md:!pr-8">
                <Button
                  className="!text-[#fff] !rounded-3xl !px-5 !py-2 !font-bold !capitalize"
                  sx={{ border: "2px solid #fff" }}
                  onClick={(e) => handleClickLogin(e)}
                >
                  Login
                </Button>
              </ListItem>
              <ListItem className="!pl-0 !pr-0">
                <Button
                  className={orangeButtonStyles}
                  onClick={(e) => handleClickSignup(e)}
                >
                  Signup
                </Button>
              </ListItem>
            </List>
          </Grid>
          <Grid>
            <h2 className="pt-5 text-[#fff] !font-black text-[25px] md:text-[50px] md:pt-14 lg:pt-16">
              Simplifying Logistics <br /> Connecting Shippers and Carriers
            </h2>
            <Typography className="text-[#fff] pt-5 pb-5 block" variant="p">
              Alautun platform makes freight logistic simple for shippers,
              carriers and owner operators.
            </Typography>
            <Button className={orangeButtonStyles}>Get Started</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="w-[100%] !drop-shadow-2xl mt-[-60px] !rounded-2xl md:w-[80%] mx-auto">
        <Paper className="!py-20 !rounded-2xl">
          <h2 className="text-[25px] !font-black inline-block px-6 md:text-[50px]">
            Industries We Serve
          </h2>
          <Typography className="pt-5 px-6">
            We cater to all industries, customizing our solutions to specific
            needs of a vertical market.
          </Typography>
          <Grid
            container
            className="md:justify-center px-8 pt-5 gap-4 md:gap-5 lg:gap-6"
          >
            <IndustryCard name={Industry} text={"Agriculture"} />
            <IndustryCard name={Industry1} text={"Food & Beverage"} />
            <IndustryCard name={Industry2} text={"Retail"} />
            <IndustryCard name={Industry3} text={"Manufacturing"} />
            <IndustryCard name={Industry4} text={"Fresh Product"} />
            <IndustryCard name={Industry5} text={"Consumer Products"} />
          </Grid>
        </Paper>
      </Grid>
      <CommonSec
        name={CommonSecImage}
        // heading="Reliable Shipper in Transport Services"
        heading="Dependable Shipping in Transportation Services"
        order="left"
        // paragraph={
        //   "Our team is well-versed in all aspects of shipping and logistics, and we pride ourselves on our attention to detail and commitment to meeting deadlines. We understand the importance of timely delivery and strive to exceed your expectations every step of the way."
        // }
        paragraph={
          "Our team has a wealth of knowledge in all areas of shipping and logistics, and we take pride in our attention to detail and ability to meet deadlines. We recognize the significance of timely delivery and work hard to exceed your expectations at every stage of the process."
        }
      />
      <CommonSec
        name={CommonSecImage1}
        heading="Join Our Transportation Service as an Owner Operator
        "
        order="right"
        paragraph={
          "Working as an owner operator with our transportation service gives you the chance to run your own truck and manage your own business within our established client network. This role provides you with the ability to control your schedule and earning potential, while still receiving assistance and resources from our team."
        }
      />
      <CommonSec
        name={CommonSecImage2}
        heading="Select the Best Carrier for Your Transportation Requirements"
        order="left"
        paragraph={
          "In order to ensure that your goods and materials are transported effectively, it is crucial to choose a carrier that meets your specific requirements. There are various carriers to choose from, including trucking companies, rail carriers, and shipping companies, each with its own set of pros and cons. It is essential to carefully evaluate your options before making a decision."
        }
      />
      <Grid className="!bg-[#F0ECEB] !w-[100%] mt-28 text-left">
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          className="bg-no-repeat flex items-center gap-5  lg:gap-24 lg:pt-0"
        >
          <Grid
            item
            lg={4}
            md={12}
            sm={12}
            sx={{ backgroundImage: `url(${Frame})`, backgroundSize: "cover" }}
            className="!flex !justify-center pt-10 text-center"
          >
            <img src={Screen} alt="Screen" classname="w-[100%]" />
          </Grid>
          <Grid item lg={6} md={12} sm={12} className="px-5 pb-10 lg:pb-0">
            {/* <Typography variant="h2 text-[40px] font-black">Get all access in your hands now</Typography> */}
            <h2 className="text-[30px] font-black md:text-[50px]">
              Download mobile applications from AppStore and Google Play Store
            </h2>

            {/* <Point text={"Easy and powerful tools for content creator. Podcasting never get easier"}/>
            <Point text={"Complete guide to start your podcast channel. Reach your audience effectively."}/>
            <Point text={"Fully remarkable assistant from our customer support. 24/7 online."}/> */}
            <Grid
              container
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid item>
                <p className="text-center mt-4 font-semibold text-xl">
                  Get Shipper App
                </p>
                <img
                  alt="carrierQr"
                  src={ShipperQr}
                  className="w-[175px] h-[175px] m-3 sm:w-[200px] sm:h-[200px] sm:my-4 mx-auto"
                />
                <Grid className="flex pt-4">
                  <Link
                    href="https://apps.apple.com/us/app/alautun-shipper/id1660931125"
                    target="_blank"
                  >
                    <img
                      src={AppStoreLogo}
                      alt="play-store-logo"
                      className="w-[140px] h-[45px] mr-4"
                    />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.alautun.shipperapp"
                    target="_blank"
                  >
                    <img
                      src={PlayStoreLogo}
                      alt="app-store-logo"
                      className="w-[140px] h-[45px] mx-4"
                    />
                  </Link>
                </Grid>
              </Grid>
              <Grid item>
                <p className="text-center mt-4 font-semibold text-xl">
                  Get Provider App
                </p>
                <img
                  alt="carrierQr"
                  src={ProviderQr}
                  className="w-[175px] h-[175px] m-3 sm:w-[200px] sm:h-[200px] sm:my-4 mx-auto"
                />
                <Grid className="flex pt-4">
                  <Link
                    href="https://apps.apple.com/us/app/alautun-provider/id1660930832"
                    target="_blank"
                  >
                    <img
                      src={AppStoreLogo}
                      alt="play-store-logo"
                      className="w-[140px] h-[45px] mr-4"
                    />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.alautun.providerapp"
                    target="_blank"
                  >
                    <img
                      src={PlayStoreLogo}
                      alt="app-store-logo"
                      className="w-[140px] h-[45px] mx-4"
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sm={12}
        lg={12}
        md={12}
        xs={12}
        className="!bg-[#F9F9F6] flex justify-between p-10"
      >
        <Grid item sm={6} lg={6} md={6} xs={12} className="!text-left">
          <img src={OrangeLogo} alt="orange-logo" />
          <Grid className="flex pt-10 gap-2 items-center">
            <HiPhone className="text-[20px]" />
            <Typography className="text-[20px]">+1-833-252-8886</Typography>
          </Grid>
          <Grid className="flex pt-2 gap-2 items-center">
            <HiPhone className="text-[20px]" />
            <Typography className="text-[20px]">+1-833-alautun</Typography>
          </Grid>
          <Grid
            item
            sm={6}
            lg={6}
            md={6}
            xs={12}
            className="flex gap-2 items-center pt-2"
          >
            <MdEmail className="text-[20px]" />
            <Typography>support@alautun.com</Typography>
          </Grid>
        </Grid>
        {/* <MenuLinks heading="Shippers" linksArr={["Login","Sign Up"]} />
        <MenuLinks heading="Carriers" linksArr={["Login","Sign Up"]} /> */}
        {/* <MenuLinks heading="Company" linksArr={["About us","Team","Careers","Press"]} /> */}
        <Grid className="pt-5 text-[18px] !text-[#000]] flex !flex-col text-left gap-2">
          {/* <Typography>© 2023 Alautun. All Rights Reserved.</Typography> */}
          {/* <Grid className="flex flex-row !gap-10">
          <Anchor to="/terms" className="!text-[#000]">Terms of Service</Anchor>
          <Anchor to="/terms" className="!text-[#000]">Privacy Policy</Anchor>
        </Grid> */}
          {/* <MenuLinks heading="" linksArr={["Terms of Service","Privacy Policy"]} /> */}
          <Anchor to="/terms" className="!text-[#000] !text-left">
            Terms of Service
          </Anchor>
          <Anchor to="/privacy-policy" className="!text-[#000] !text-left">
            Privacy Policy
          </Anchor>
        </Grid>
      </Grid>
      <Grid className="p-5 flex justify-center text-[18px] !text-[#000]]">
        <Typography>© 2023 Alautun. All Rights Reserved.</Typography>
        {/* <Grid className="flex !gap-10">
          <Anchor to="/terms" className="!text-[#000]">Terms of Service</Anchor>
          <Anchor to="/terms" className="!text-[#000]">Privacy Policy</Anchor>
        </Grid> */}
      </Grid>
      <PopOver open={openSignup} reference={signupEl} use="signup" />
      <PopOver open={openLogin} reference={loginEl} use="login" />
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Grid className="p-10">
          <List className="!text-[18px]">
            <ListItem>
              <Link
                href="https://shipper.alautun.com"
                className={orangeButtonStyles}
                sx={{ textDecoration: "none" }}
                target="_blank"
              >
                Shippers
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="https://carrier.alautun.com"
                className={orangeButtonStyles}
                sx={{ textDecoration: "none" }}
                target="_blank"
              >
                Carriers
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default Home;
