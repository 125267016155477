import {
  Button,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

import Logo from "../assets/alautun-logo.svg";

import { useTheme } from "@mui/material/styles";
import { FiMenu } from "react-icons/fi";
import PopOver from "./PopOver";

const TopCommon = () => {
  const menuLinkStyles = "!text-[#fff]";
  const orangeButtonStyles =
    "!bg-[#FF8243] !text-[#fff] !px-5 !py-2 !rounded-3xl !font-bold !capitalize";

  const [openSignup, setOpenSignUp] = useState(false);
  const [signupEl, setSignupEl] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [loginEl, setLoginEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClickSignup = (e) => {
    setSignupEl(e.target);
    setOpenSignUp(!openSignup);
    setOpenLogin(false);
  };

  const handleClickLogin = (e) => {
    setLoginEl(e.target);
    setOpenLogin(!openLogin);
    setOpenSignUp(false);
  };

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDownScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <div className="bg-black ">
        <Grid className="flex mx-4 h-[80px] items-center justify-center gap-4 md:gap-10 lg:justify-between">
          {smScreen || mdScreen || xsScreen || lgDownScreen ? (
            <FiMenu
              className="text-[#fff] !text-[20px] cursor-pointer md:text-[30px]"
              onClick={() => setOpenDrawer(true)}
            />
          ) : (
            <></>
          )}
          <Link href="https://www.alautun.com/">
            <img
              src={Logo}
              alt="Logo"
              className="w-[100px] md:w-[150px] cursor-pointer"
              href="#"
            />
          </Link>
          {lgScreen || xlScreen ? (
            <List className="flex items-start !text-[18px]">
              <ListItem>
                <Link
                  href="https://shipper.alautun.com"
                  target="_blank"
                  className={menuLinkStyles}
                >
                  Shippers
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://carrier.alautun.com"
                  target="_blank"
                  className={menuLinkStyles}
                >
                  Carriers
                </Link>
              </ListItem>
              {/* <ListItem>
                <Link href="#" className={menuLinkStyles}>
                  Company
                </Link>
              </ListItem>
              <ListItem>
                <Link href="#" className={menuLinkStyles}>
                  Blog
                </Link>
              </ListItem> */}
            </List>
          ) : (
            <></>
          )}
          <List className="flex items-start">
            <ListItem className="!pr-2 md:!pr-8">
              <Button
                className="!text-[#fff] !rounded-3xl !px-5 !py-2 !font-bold !capitalize"
                sx={{ border: "2px solid #fff" }}
                onClick={(e) => handleClickLogin(e)}
              >
                Login
              </Button>
            </ListItem>
            <ListItem className="!pl-0 !pr-0">
              <Button
                className={orangeButtonStyles}
                onClick={(e) => handleClickSignup(e)}
              >
                Signup
              </Button>
            </ListItem>
          </List>
        </Grid>
        <PopOver open={openSignup} reference={signupEl} use="signup" />
        <PopOver open={openLogin} reference={loginEl} use="login" />
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Grid className="p-10">
            <List className="!text-[18px]">
              <ListItem>
                <Link
                  href="https://shipper.alautun.com"
                  className={orangeButtonStyles}
                  sx={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Shippers
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://carrier.alautun.com"
                  className={orangeButtonStyles}
                  sx={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Carriers
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Drawer>
      </div>
    </>
  );
};

export default TopCommon;
