import React from "react";
import { Grid, Typography } from "@mui/material";

const CommonSec = ({ name, heading, paragraph, order }) => {
  const gridStyles = "w-[95%] mx-auto !flex !items-center !justify-between pt-14 px-5 md:pt-32";
  const headingStyles = "text-[30px] !font-black mb-0 lg:text-left md:text-[50px] md:mb-5";
  const pStyles = "!text-[#12141D] !block !text-[18px] text-center pt-[8px] md:pt-[20px] lmd:text-center lg:text-left";
  return (
    <>
      {order === "left" ? (
        <Grid container lg={11} md={11} sm={10} className={gridStyles}>
          <Grid item lg={6} md={5} sm={12} className="flex justify-center lg:justify-start">
          <img src={name} alt="img"/>
          </Grid>
          <Grid item lg={6} md={7} sm={12}>
            <h2  className={headingStyles} sx={{fontFamily : "Proxima Nova"}}>{heading}</h2>
            <p  className={pStyles}>{paragraph}</p>
          </Grid>
        </Grid>
      ) : (
        <Grid container lg={11} md={11} sm={10} className={"w-[95%] mx-auto !flex !flex-col-reverse !items-center !justify-between pt-14 px-5 md:pt-32 lg:!flex-row"}>
          <Grid item lg={6} md={7} sm={12}>
          <h2  className={headingStyles} sx={{fontFamily : "Proxima Nova"}}>{heading}</h2>
            <p  className={pStyles}>{paragraph}</p>
          </Grid>
          <Grid item lg={6} md={5} sm={12} className="flex justify-center lg:justify-end">
          <img src={name} alt="img"/>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CommonSec;
