import { Grid, Link, Popper } from "@mui/material";
import React from "react";
import { useState } from "react";

const PopOver = ({ open, reference, use }) => {
  // const [arrowRef,setArrowRef] = useState(null);
  const arrowUpStyles = {
    width: "0",
    height: "0",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #fff",
    marginTop: "-30px",
    marginLeft: "auto",
    marginRight: "auto",
  };
  return (
    <Popper open={open} anchorEl={reference}>
      {() => (
        <>
          <Grid className="!bg-[#fff] !p-5 rounded-2xl shadow-lg mt-2">
            <div style={arrowUpStyles}></div>
            {use === "login" ? (
              <>
                <Link
href="https://shipper.alautun.com/login" 
 target="_blank"
                  className="block !text-[#000] pt-5 pb-2"
                  sx={{ textDecoration: "none" }}
                >
                  {"Login for Shipper"}
                </Link>
                <hr />

                <Link
href="https://carrier.alautun.com/login" 
 target="_blank"               
    className="block !text-[#000] pt-5 pb-2"
                  sx={{ textDecoration: "none" }}
                >
                  {"Login for Carrier"}
                </Link>
              </>
            ) : (
              <>
                <Link
href="https://shipper.alautun.com/signup" 
 target="_blank"                 
  className="block !text-[#000] pt-5 pb-2"
                  sx={{ textDecoration: "none" }}
                >
                  {"Signup for Shipper"}
                </Link>
                <hr />

                <Link
href="https://carrier.alautun.com/sign-up" 
 target="_blank"            
         className="block !text-[#000] pt-5 pb-2"
                  sx={{ textDecoration: "none" }}
                >
                  {"Signup for Carrier"}
                </Link>
              </>
            )}
            {/* <Link
              href="#"
              className="block !text-[#000] pt-5 pb-2"
              sx={{ textDecoration: "none" }}
            >
              {use === "login" ? "Login for Shippers" : "Signup for Shippers"}
            </Link>
            <hr />
            <Link
              href="#"
              className="block !text-[#000] pt-2"
              sx={{ textDecoration: "none" }}
            >
              {use === "login" ? "Login for Carriers" : "Signup for Carriers"}
            </Link> */}
          </Grid>
        </>
      )}
    </Popper>
  );
};

export default PopOver;
