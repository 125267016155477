import { Button } from "@mui/material";

// import { useAcceptTermsQuery } from 'queries/NewShipper';
import React from "react";
import TopCommon from "../components/TopCommon";

const TermsAndConditions = () => {
  return (
    <>          <TopCommon />

          <div class="w-[80%] mx-auto my-10">

<p class="text-[0.85rem]">&nbsp;ALAUTUN, INC.</p>
<br />
<p class="text-center text-[1.3rem]">TERMS AND CONDITIONS</p>
<br />
<br />
<p class="font-bold text-[0.85rem]">&nbsp; 1. INTRODUCTION</p>
<p>
  The following Website Terms of Use (“Website Terms” or “Agreement”) is a
  legally binding agreement between
  <span class="font-bold"> ALAUTUN INC. </span> d/b/a
  <span class="font-bold"> ALAUTUN</span> (“ALAUTUN,” “we,” “our” or “us”)
  and the users (“User,” “you,” “your,” “Shipper,” or “Carrier”) of our
  Platform. Our “Platform” consists of our website i.e., located at
  <span class="underline decoration-gray-500">www.alautun.com</span> (the
  “Website”) and our mobile applications (“App”) made available on Google
  Playstore or Apple Appstore. In addition to access to our Platform we
  also provide a range of related services (“Services”) to our Users.
  These Website Terms governs your access to and use of ALAUTUN’s Platform
  and Services and constitutes a legal agreement between you and us.
</p>
<br />
<br />
<p>
  Capitalized terms not defined herein shall have the same meaning
  ascribed to them either under the
  <span class="underline decoration-gray-500">Shipper Contract</span>, the
  <span class="underline decoration-gray-500">Carrier Contract</span> or
  the <span class="underline decoration-gray-500">Privacy Policy</span>.
</p>
<br />
<br />
<p>
  Any User can only use the Platform and the Services when they read and
  accept the applicable
  <span class="underline decoration-gray-500">Shipper Contract</span> and
  the
  <span class="underline decoration-gray-500">Carrier Contract</span> and
  the <span class="underline decoration-gray-500">Privacy Policy</span> in
  addition to reading and accepting these Website Terms.
</p>
<br />
<br />
<p class="font-bold text-[1.2rem]">
  PLEASE CLICK ON THE ABOVE LINKS TO UNDERSTAND THE ENTIRE RANGE OF YOUR
  RIGHTS AND RESPONSIBILITIES UNDER THIS AGREEMENT AND OTHERWISE.
</p>
<br />
<br />

<p class="font-bold text-[1.2rem]">
  IF YOU DO NOT AGREE WITH THE TERMS SET FORTH IN THIS AGREEMENT, DO NOT
  REGISTER OR USE OUR WEBSITE OR THE SERVICES THEREUNDER. IF YOU USE THE
  SERVICES OFFERED HEREWITH, YOU ARE BOUND BY THESE WEBSITE TERMS.
</p>
<br />
<br />
<p class="font-bold text-[1.2rem]">
  PLEASE NOTE THAT THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN
  INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS
  ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A
  DISPUTE.
</p>
<br />
<br />
<p class="font-bold text-[0.85rem]">&nbsp; 2. REGISTRATION</p>
<p>
  In order to use our Platform and Services, you will be required to
  register with us. Once you visit our Website, register with us i.e.,
  review these User Terms and any related documents and provide us with
  requires registration details, you will require our approval to create
  an Account on our Platform. Once you receive the approval from us, you
  must set up an account with us before you can use our Platform and
  Services. Please click
  <span class="underline decoration-gray-500">here</span> to register.
  Unless you register, you may not use our Platform or Services. In
  addition to setting up an account, if you are Shipper, you will have to
  click through, read and agree to the
  <span class="underline decoration-gray-500">Shipper Contract</span>. Do
  not use our Platform if you do not agree to the terms and conditions of
  the <span class="underline decoration-gray-500">Shipper Contract</span>.
  If you use the Platform as a Shipper, you are bound by the terms and
  conditions of the
  <span class="underline decoration-gray-500">Shipper Contract</span>. In
  addition to setting up an account, if you are Carrier, you will have to
  click through, read and agree to the
  <span class="underline decoration-gray-500">Carrier Contract</span>. Do
  not use our Platform if you do not agree to the terms and conditions of
  the <span class="underline decoration-gray-500">Carrier Contract</span>.
  If you use the Platform as a Carrier, you are bound by the terms and
  conditions of the
  <span class="underline decoration-gray-500">Carrier Contract</span>.
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;3. TERRITORIAL RESTRICTIONS</p>
<p>
  Use of the ALAUTUN Platform and Services is currently restricted to the
  United States of America, Canada, and Mexico (the “Registered
  Territory”). Please do not access and use our Platform or Services if
  you reside in the European Union or outside the Registered Territory as
  our Platform and Services are not available for access and use in the
  European Union. We will inform you via email if we decide to offer our
  Platform and Services in the European Union.
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;4. OUR PRIVACY POLICY</p>
<p>
  Our
  <span class="underline decoration-gray-500">Privacy Policy </span>
  describes how we handle the information you provide to us when you use
  our Platform and Services. You understand that through your use of the
  Platform and Services you consent to the collection and use (as set
  forth in the Privacy Policy) of this information, including the transfer
  of this information to the United States, and/or other countries for
  storage, processing and use by ALAUTUN and its affiliates. 
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;5. PURPOSE OF THE WEBSITE</p>
<p>
  Before you use or access our Website you must read and agree to these
  Website Terms. By accessing, using, or downloading materials from the
  Website you agree to follow and be bound by these Website Terms. All
  rights, title, and interest not expressly granted are reserved. 
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp;6. PURPOSE AND USE OF THE PLATFORM
</p>
<p>
  ALAUTUN helps to connect “Carriers” (as defined under the Carrier
  Contract) and “Shippers” (as defined under the Shippers
  Contract) for the purpose of shipping cargo throughout North America by
  providing access to the Platform whereby Carriers post details of
  requests for shipping and get matched with Shippers submit. ALAUTUN may
  also offer fleet management systems to Users that allow small trucking
  companies to manage their drivers, equipment, dispatching loads and
  managing invoices online.
</p>
<br />
<br />
<p>
  As mentioned under Section 1 of this Website Terms, a “User” means any
  user of the Platform and the Services and may be a Carrier or a Shipper.
</p>
<br />
<br />
<p class="font-bold">          i. For Carriers </p>
<br />
<p>
  To the extent you are a Carrier, the provisions in this Agreement
  regarding Carriers and the separate Online Carrier Contract apply to
  you. You must click through, read and agree to the Online Carrier
  Contract before you will be able to complete your registration and use
  our Platform as a Carrier.  
</p>
<br />

<p class="font-bold">          ii. For Shippers </p>
<br />
<p>
  To the extent you are a Shipper, the provisions in this Agreement
  regarding Shippers and the separate Online Shipper Contract both apply
  to you. You must click through, read and agree to the Online Shipper
  Contract before you will be able to complete your registration and use
  our Platform as a Shipper. 
</p>
<br />
<p class="font-bold">          iii. For Users </p>
<br />
<p>
  To the extent you are a User, the provisions in this Agreement regarding
  Users apply to you.
</p>
<br />
<p>
  FOR THE AVOIDANCE OF DOUBT, ALAUTUN PROVIDES THE PLATFORM FOR LOAD
  POSTING AND ACCEPTING CARRYING REQUIREMENTS AS A BROKER AND NOT AS A
  CARRIER AND DOES NOT GUARANTEE THE SERVICE PROVIDED BY A CARRIER.ALAUTUN
  IS NOT ACTING AS A CARRIER IN CONNECTION WITH ITS PLATFORM. 
</p>
<br />

<p class="font-bold text-[0.85rem]">
  &nbsp;7. LICENSE TO USE THE PLATFORM
</p>
<p>
  Subject to the terms of this Agreement, ALAUTUN grants you a
  non-transferable, nonexclusive right to use the Platform and the
  Services for your business use during the term of this Agreement. You
  may not permit any other person or entity to use your account for the
  Platform. 
</p>
<br />
<br />
<p>
  Subject to the terms of this Agreement, ALAUTUN grants you a
  nontransferable, non-exclusive right to install and use the App, in
  executable object code format only, solely on your own handheld mobile
  device and for your business use during the term of this Agreement. You
  may not permit any other person or entity to use your account for the
  Mobile App. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;8. RESTRICTIONS</p>
<p>
  The rights granted to you in this Agreement are subject to the following
  restrictions: (a) you shall not license, sell, rent, lease, transfer,
  assign, distribute, host, or otherwise commercially exploit the
  Platform; (b) you shall not modify, make derivative works of,
  disassemble, reverse compile or reverse engineer any part of the
  Platform; (c) you shall not access the Platform in order to build a
  similar or competitive service, website, or Mobile App; and (d) except
  as expressly stated herein, no part of the Website and/or App may be
  copied, reproduced, distributed, republished, downloaded, displayed,
  posted or transmitted in any form or by any means. Any future release,
  update, or other addition to functionality of the Platform shall be
  subject to the terms of this Agreement. All copyright and other
  proprietary notices on any Platform content must be retained on all
  copies thereof. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;9. SHIPPER CONTRACT</p>
<p>
  The
  <span class="underline decoration-gray-500">Shipper Contract</span> is
  an agreement that is executed between you as the “Shipper,” and ALAUTUN
  as a broker (the “Broker”). The Online Shipper Contract sets forth the
  legal rules and responsibilities of the Shipper and the Broker to
  memorialize the ALAUTUN Platform and Services. PLEASE CLICK ON THE LINKS
  TO KNOW MORE ABOUT YOUR RIGHTS AND RESPONSIBILITIES AS A SHIPPER. YOU
  MUST CLICK THROUGH, READ AND AGREE TO THE ONLINE SHIPPER CONTRACT BEFORE
  COMPLETING YOUR REGISTRATIONS AS A SHIPPER. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;10. CARRIER CONTRACT</p>
<p>
  The
  <span class="underline decoration-gray-500">Carrier Contract</span> is
  an agreement that is executed between you as the “Carrier,” and ALAUTUN
  as the Broker. This agreement sets forth the rules and responsibilities
  of a Carrier and Broker as per the requirement of the ALAUTUN Platform
  and Services. PLEASE CLICK ON THE LINKS TO KNOW MORE ABOUT YOUR RIGHTS
  AND RESPONSIBILITIES AS A CARRIER. YOU MUST CLICK THROUGH, READ AND
  AGREE TO THE ONLINE CARRIER CONTRACT BEFORE COMPLETING YOUR
  REGISTRATIONS AS A CARRIER. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;11. SAAS AGREEMENT</p>
<p>
  Under the
  <span class="underline decoration-gray-500">SAAS Agreement</span>,
  ALAUTUN will provide a Customer and its authorized users (the Customer’s
  Shippers and Carriers) access to and use of ALAUTUN’s next generation
  digital freight Platform and transportation brokerage Services.  This
  agreement sets forth the rules and responsibilities of the Customer and
  its authorized users. 
</p>
<br />
<p class="font-bold">
  PLEASE CLICK ON THE LINKS TO KNOW MORE ABOUT YOUR RIGHTS AND
  RESPONSIBILITIES AS A CUSTOMER. YOU MUST CLICK THROUGH, READ AND AGREE
  TO THE ONLINE
  <span class="underline decoration-gray-500">SAAS AGREEMENT</span> BEFORE
  COMPLETING YOUR REGISTRATIONS AS A CUSTOMER. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;12. PAYMENT TERMS</p>
<p>
  By providing a credit card or other payment method accepted by ALAUTUN
  and its third-party payment processor, you represent and warrant that
  you are authorized to use the designated payment method and that you
  authorize us (or our third-party payment processor) to charge your
  payment method for the total amount of your order (including any
  applicable taxes and other charges). Please note that ALAUTUN does not
  store any credit card, or other such related financial information
  collected from its Users. However, the third-party payment processor
  used by ALAUTUN may collect and store such information. Therefore,
  please ensure that you review the terms and conditions of our
  third-party payment processor to understand your rights and
  responsibilities. If the payment method you provide cannot be verified,
  is invalid or is otherwise not acceptable, your order may be suspended
  or cancelled. You must resolve any problem we encounter in order to
  proceed with your order. In the event you want to change, or update
  payment information associated with your ALAUTUN account, you can do so
  at any time by logging into your account and editing your payment
  information. All Services offered on our Website and/or App are subject
  to availability, and we reserve the right to impose quantity limits on
  any order, to reject all or part of an order and to discontinue offering
  certain Platform without prior notice. Prices for the Services are
  subject to change at any time, but changes will not affect any order for
  Services you have already placed. 
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp; 13. USER CONTENT AND CREATION OF ANONYMOUS DATA
</p>
<p>
  “User Content” means any and all information, data, and other content
  that a User submits to, or uses with, the Platform or the Services. User
  Content includes the information provided in a shipment request or
  acceptance. You are solely responsible for your User Content. You assume
  all risks associated with use of your User Content, including any
  reliance on its accuracy, completeness or usefulness by others, or any
  disclosure of your User Content that makes you or any third party
  personally identifiable. You hereby represent and warrant that your User
  Content does not violate the Acceptable Use Policy (as detailed
  under Section 12). For the avoidance of doubt, User Content may include
  third party content you submit. You agree not to submit third party
  content unless you have the consent of the applicable third-party owner
  of such content. You may not state or imply that your User Content is in
  any way provided, sponsored or endorsed by ALAUTUN. Because you alone
  are responsible for your User Content (and not ALAUTUN), you may be
  exposed to liability if, for example, your User Content violates
  the Acceptable Use Policy. Because we do not control User Content, you
  acknowledge and agree that we are not responsible for any User Content
  and we make no guarantees regarding the accuracy, currency, suitability,
  or quality of any User Content, and we assume no responsibility for any
  User Content. 
</p>
<br />
<br />
<p>
  We may create anonymous data records (“Anonymous Data“) from your User
  Content by using commercially reasonable efforts to exclude any and all
  information (such as ALAUTUN name) that makes the data identifiable to
  you. We may use and disclose Anonymous Data for any purpose, including
  improving the Platform and the Services. 
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;14. FEEDBACK</p>
<p>
  If you provide ALAUTUN any feedback, suggestions, bug reports, system
  errors, and other information or ideas regarding the Platform or
  Services (“Feedback“), you hereby assign to ALAUTUN all rights in the
  Feedback and agree that ALAUTUN shall have the right to use such
  Feedback and related information in any manner it deems appropriate.
  ALAUTUN will treat any Feedback you provide to ALAUTUN as
  non-confidential and non-proprietary. You agree that you will not submit
  to ALAUTUN any information or ideas that you consider to be confidential
  or proprietary. If you agree to participate in any case studies, you
  agree that information you provide in connection with the case study is
  deemed Feedback and that ALAUTUN may use your name in connection with
  such Feedback.
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;15. ACCEPTABLE USE POLICY</p>
<p>The following details ALAUTUN’s “Acceptable Use Policy.” </p>
<br />
<br />
<p>
  You agree not to use the Platform to collect, upload, transmit, display,
  or distribute any User Content: (a) that violates any third-party right,
  including any copyright, trademark, patent, trade secret, moral right,
  privacy right, right of publicity, or any other intellectual property or
  proprietary right; (b) that is unlawful, harassing, abusive, tortious,
  threatening, harmful, invasive of another’s privacy, vulgar, defamatory,
  false, intentionally misleading, trade libelous, pornographic, obscene,
  patently offensive (e.g., material that promotes racism, bigotry,
  hatred, or physical harm of any kind against any group or individual) or
  otherwise objectionable material of any kind or nature or which is
  harmful to minors in any way; or (c) in violation of any law,
  regulation, or obligations or restrictions imposed by any third party.
</p>
<br />
<br />
<p>
  In addition, you agree not to use the Platform to: (a) upload, transmit,
  or distribute any computer viruses, worms, or any software intended to
  damage or alter a computer system or data; (b) send unsolicited or
  unauthorized advertising, promotional materials, junk mail, spam, chain
  letters, pyramid schemes, or any other form of duplicative or
  unsolicited messages, whether commercial or otherwise; (c) harvest,
  collect, gather or assemble information or data regarding other Users,
  including e-mail addresses, without their consent; (d) interfere with,
  disrupt, or create an undue burden on servers or networks connected to
  the Platform or violate the regulations, policies or procedures of such
  networks; (e) attempt to gain unauthorized access to the Platform, other
  computer systems or networks connected to or used together with the
  Platform, through password mining or other means; (f) harass or
  interfere with another User’s use and enjoyment of the Platform; or (g)
  introduce software or automated Agents or scripts to the Platform so as
  to produce multiple accounts, generate automated searches, requests and
  queries, or to strip, scrape, or mine data from the Platform.
</p>
<br />
<br />
<p>
  We reserve the right (but have no obligation) to review any User
  Content, investigate, and/or take appropriate action against you in our
  sole discretion (including removing or modifying your User Content,
  terminating your account in accordance with Section 15, and/or reporting
  you to law enforcement authorities) if you violate the Acceptable Use
  Policy or any other provision of this Agreement or otherwise create
  liability for us or any other person.
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp;16. LINK TO THIRD-PARTY WEBSITES
</p>
<p>
  This Website may contain hyperlinks to websites controlled by parties
  other than the ALAUTUN. The ALAUTUN is not responsible for and does not
  endorse or accept any responsibility over the content or use of these
  third-party websites. Do not open an account with such third parties
  unless you have read, understood and agree with the terms and conditions
  and privacy policy of such other third-parties websites 
</p>
<br />

<p class="font-bold text-[0.85rem]">
  &nbsp;17. COPYRIGHT INFRINGEMENT AND DMCA NOTICE
</p>
<p>
  If you believe that any content on our Website or our Platform violates
  your copyright, and wish to have the allegedly infringing material
  removed, the following information in the form of a written notification
  (pursuant to 17 U.S.C. § 512(c) (“DMCA Takedown Notice”) must be
  provided to our designated Copyright Agent.
</p>
<br />
<li>Your physical or electronic signature; </li>
<li>
  Identification of the copyrighted work(s) that you claim to have been
  infringed; 
</li>
<li>
  Identification of the material on our services that you claim is
  infringing and that you request us to remove; 
</li>
<li>Sufficient information to permit us to locate such material; </li>
<li>Your address, telephone number, and e-mail address; </li>
<li>
  A statement that you have a good faith belief that use of the
  objectionable material is not authorized by the copyright owner, its
  agent, or under the law; and 
</li>
<li>
  A statement that the information in the notification is accurate, and
  under penalty of perjury, that you are either the owner of the copyright
  that has allegedly been infringed or that you are authorized to act on
  behalf of the copyright owner. 
</li>
<br />
<p>
  The mailing address for the Designated Copyright Agent to receive DMCA
  Takedown Notices is Mr. Syed Aman,
  <span class="font-bold"> ALAUTUN INC.</span>, Attn: DMCA Notice, 2524
  Buena Vista AVE, CA-94501. You may also email our Designated Copyright
  Agent at info@alautun.com.  You acknowledge that for us to be authorized
  to take down any content, your DMCA takedown notice must comply with all
  the requirements of this Section 14. Please note that, pursuant to 17
  U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a
  written notification automatically subjects the complaining party to
  liability for any damages, costs and attorney’s fees incurred by us in
  connection with the written notification and allegation of copyright
  infringement. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;18. TERMS AND TERMINATION</p>
<p>
  Subject to this Section, this Agreement will remain in full force and
  effect while you use the Service. ALAUTUN may at any time terminate this
  Agreement with you in its sole discretion if: (a) you have breached any
  provision of this Agreement (or have acted in a manner that clearly
  shows you do not intend to, or are unable to, comply with this
  Agreement); (b) ALAUTUN is required to do so by law (for example, where
  the provision of the Service to you is, or becomes, unlawful); (c)
  ALAUTUN has elected to discontinue the Service; or (d) for your poor
  performance as a Shipper or Carrier in our sole discretion. Upon
  termination of this Agreement, your account and right to access and use
  the Platform will terminate immediately. ALAUTUN will not have any
  liability whatsoever to you for any termination of this Agreement,
  including for termination of your account or deletion of your User
  Content. EVEN AFTER THIS AGREEMENT IS TERMINATED, THE PROVISIONS OF THIS
  AGREEMENT REGARDING INDEMNITY, CONFIDENTIALITY, DISCLAIMERS AND RELEASE,
  LIMITATIONS OF LIABILITY, AND DISPUTE RESOLUTION, SHALL CONTINUE TO BIND
  SHIPPERS AND CARRIERS. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;19. MODIFICATIONS</p>
<p>
  ALAUTUN reserves the right, at its sole discretion, to change or modify
  these Website Terms at any time. In the event, we modify these Website
  Terms, such modifications shall be binding on you only upon your
  acceptance of the modified Website Terms. We will inform you about the
  modifications via email or comparable means within 15 days of such
  modification. We will also post the modified version on this page. Your
  continued use of the Platform and the Services shall constitute your
  consent to such changes. ALAUTUN may change, modify, suspend, or
  discontinue any aspect of the Platform at any time without notice or
  liability. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;20. INDEMNITY</p>
<p>
  You agree to defend, indemnify and hold ALAUTUN (and its officers,
  employees, and agents) harmless, including costs and attorneys’ fees,
  from any claim or demand made by any third party due to or arising out
  of your (a) use of the Services, the Platform and the Rewards, (b) User
  Content, (c) interaction with any other User, (d) violation of this
  Agreement; (e) violation of applicable laws or regulations; or (f) your
  shipment contents (if you are a Shipper) or your shipment services (if
  you are a Carrier). ALAUTUN reserves the right, at your expense, to
  assume the exclusive defense and control of any matter for which you are
  required to indemnify us and you agree to cooperate with our defense of
  these claims. You agree not to settle any matter without the prior
  written consent of ALAUTUN. ALAUTUN will use reasonable efforts to
  notify you of any such claim, action or proceeding upon becoming aware
  of it. 
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp;21. ANTI- BRIBERY REPRESENTATIONS AND WARRANTIES
</p>
<p>
  ALAUTUN and its officers, directors, employees, agents, and anyone
  acting on its behalf (collectively, the “
  <span class="font-bold">Representatives</span>“) are in compliance with
  all applicable anti-bribery and anti-corruption laws, including the US
  <span class="font-bold">Foreign Corrupt Practices Act</span> and
  Canadian 
  <span class="font-bold">Corruption of Public Officials Act</span>.
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp;22. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
</p>
<p>
  THE SERVICE AND USE OF THE PLATFORM AND RELATED SERVICES IS PROVIDED
  “AS-IS” AND “AS AVAILABLE” AND WE (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM
  ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
  INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
  PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
  NONINFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE
  SERVICE: (A) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (B) WILL BE
  AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C)
  WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE,
  COMPLETE, LEGAL, OR SAFE; OR (D) RESULT IN ANY REVENUE, PROFITS, OR COST
  REDUCTION. THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER
  PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
  COMMUNICATIONS. WE ARE NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES
  OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS. 
</p>
<br />
<br />
<p>
  YOU FURTHER EXPRESSLY AGREE THAT YOUR USE OF THIS WEBSITE IS AT YOUR
  SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE
  LAW, ALAUTUN DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
  BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
  FOR A PARTICULAR PURPOSE. ALAUTUN DOES NOT WARRANT THAT THIS WEBSITE;
  INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR
  SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
  THE ALAUTUN WEBSITE; ITS SERVERS; OR E-MAIL SENT FROM ALAUTUN ARE FREE
  OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE ALAUTUN WILL NOT BE LIABLE
  FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE ALAUTUN WEBSITE
  OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
  SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
  THROUGH THE WEBSITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
  INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE
  SPECIFIED IN THE AGREEMENT. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS
  ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
  EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
  ADDITIONAL RIGHTS
</p>
<br />

<p class="font-bold text-[0.85rem]">
  &nbsp;23. APPLICABLE DISCLAIMERS AND RELEASES
</p>
<p>
  ALAUTUN OFFERS THE PLATFORM TO CONNECT SHIPPERS AND CARRIERS BUT DOES
  NOT AND DOES NOT INTEND TO ACT IN ANY WAY AS A CARRIER, COURIER, FREIGHT
  FORWARDER, SHIPPING PROVIDER OR BROKER. IT IS UP TO THE THIRD-PARTY
  CARRIER TO PROVIDE SHIPPING SERVICES; WHICH MAY BE SCHEDULED THROUGH THE
  USE OF THE PLATFORM. ALAUTUN HAS NO RESPONSIBILITY OR LIABILITY FOR ANY
  SHIPPING SERVICES PROVIDED TO SHIPPERS BY SUCH THIRD-PARTY CARRIERS.
  ALAUTUN SHALL NOT BE DEEMED TO BE AN AGENT OR A PARTNER OF CARRIER OR
  SHIPPER FOR ANY REASON. CARRIERS SHALL NOT BE DEEMED TO BE A
  SUBCONTRACTOR, AGENT OR EMPLOYEE OF ALAUTUN FOR ANY REASON.
</p>
<br />
<br />
<p>
  ALAUTUN CAUTIONS THAT DRIVING WHILE USING PHONES IS DANGEROUS AND
  AGAINST THE LAW. IF CARRIERS USE THE APP WHILE DRIVING, THEY DO SO AT
  THEIR OWN RISK, AND ALAUTUN IS NOT IN ANY WAY RESPONSIBLE FOR SUCH
  IMPROPER USE. 
</p>
<br />
<br />
<p>
  ALAUTUN DOES NOT INDEPENDENTLY ASSESS THE SUITABILITY, LEGALITY,
  REGULATORY COMPLIANCE, QUALITY OR ABILITY OF ANY CARRIERS, SHIPPERS,
  CARGO AND SHIPPING SERVICES SCHEDULED THROUGH THE USE OF THE PLATFORM,
  AND WE MAKE NO WARRANTY REGARDING THE FOREGOING. BY USING THE SERVICE,
  YOU OR YOUR SHIPMENTS MAY BE EXPOSED TO SITUATIONS THAT ARE POTENTIALLY
  DANGEROUS, OFFENSIVE, HARMFUL, UNSAFE OR OTHERWISE OBJECTIONABLE. YOU
  USE THE SERVICE AT YOUR OWN RISK. YOUR INTERACTIONS WITH OTHER USERS AND
  THIRD PARTIES ARE SOLELY BETWEEN YOU AND SUCH USER OR THIRD PARTY. USERS
  AGREE THAT ALAUTUN WILL NOT BE RESPONSIBLE FOR ANY LOSS OR
  DAMAGE INCURRED AS THE RESULT OF ANY SUCH INTERACTIONS. IF THERE IS A
  DISPUTE BETWEEN ANY USERS OR THIRD PARTIES, ALAUTUN IS UNDER NO
  OBLIGATION TO BECOME INVOLVED. 
</p>
<br />
<br />
<p>
  YOU HEREBY WAIVE AND RELEASE ALAUTUN (AND OUR SUPPLIERS, OFFICERS,
  EMPLOYEES, AGENTS, SUCCESSORS AND ASSIGNS) FROM, AND HEREBY WAIVE AND
  RELINQUISH, EACH AND EVERY PAST, PRESENT, AND FUTURE DISPUTE, CLAIM,
  CONTROVERSY, DEMAND, RIGHT, OBLIGATION, LIABILITY, ACTION AND CAUSE OF
  ACTION OF EVERY KIND AND NATURE (INCLUDING PERSONAL INJURIES, DEATH, AND
  PROPERTY DAMAGE), ARISING FROM YOUR USE OF THE SERVICE, OR IN ANY WAY
  RELATED TO OTHER USERS OR THIRD PARTIES. SOME JURISDICTIONS DO NOT ALLOW
  THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
  APPLY TO YOU. 
</p>
<br />
<p class="font-bold text-[0.85rem]">
  &nbsp;24. GOVERNING LAW, ARBITRATION AND JURISDICTION
</p>
<p>PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS.</p>
<br />
<br />
<p>
  All matters relating to your access to, or use of, this Website will be
  governed by U.S. federal law or the laws of the State of California. 
</p>
<br />
<br />
<p>
  Any dispute, claim or controversy arising out of or relating to these
  Website Terms, including the determination of the scope or applicability
  of these Website Terms to arbitrate, will be determined by arbitration
  in the State of California before one arbitrator. The arbitration will
  be administered by the American Arbitration Association (“AAA”) under
  the Commercial Arbitration Rules and Supplementary Procedures for
  Consumer Related Disputes then in effect for the AAA, except as provided
  herein. Judgment on the Award may be entered in any court having
  jurisdiction. ANY ARBITRATION UNDER THESE WEBSITE TERMS AND CONDITIONS
  WILL TAKE PLACE ON AN INDIVIDUAL BASIS. THE PARTIES AGREE THAT THEY ARE
  WAIVING THEIR RIGHT TO PARTICIPATE IN CLASS ACTION. This Section will
  not preclude parties from seeking provisional remedies in aid of
  arbitration from a court of appropriate jurisdiction. 
</p>
<br />
<br />
<p>
  You may not access, download, use, or export the information, software,
  products or services contained on this website in violation of U.S.
  export laws or regulations, or in violation of any applicable local laws
  or regulations. 
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;25. SEVERABILITY</p>
<p>
  In the event any provisions of these Website Terms are found to be
  contrary to any law or regulation of an administrative or governmental
  agency or body, such provision will be modified and interpreted to
  accomplish the objectives of such provision to the greatest extent
  possible under applicable law, and the remaining provisions will
  continue in full force and effect. In the event such a provision cannot
  be modified and becomes invalidated or unenforceable, its invalidation
  or unenforceability will not affect the validity or enforceability of
  any other provision of these Website Terms. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;26. RELATIONSHIP OF PARTIES</p>
<p>
  The parties hereto are independent contractors, and nothing contained
  herein shall be interpreted as creating any relationship other than that
  of independent contracting parties. The parties shall not be construed
  as being partners, joint ventures, shareholders, employer/employee,
  agent/servant. The User has no power or authority to bind ALAUTUN to any
  obligation, agreement, debt or liability. The User shall not hold itself
  out as an agent or representative of ALAUTUN. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;27. CONTACTING ALAUTUN</p>
<p>
  If you have any questions about this Website Terms or any of the related
  terms i.e., either the Online Shipper Contract or the Online Carried
  Contract, please feel free to contact us at
  <span class="underline decoration-gray-500">info@alautun.com</span>.
</p>
<br />
<br />

<p class="font-bold">
  PLEASE NOTE THAT BY USING THE ALAUTUN WEBSITE, APP AND/OR SERVICES
  EITHER AS A SHIPPER, A CARRIER, OR A USER YOU ARE AGREEING TO BE BOUND
  BY AND TO COMPLY WITH THE TERMS DESCRIBED HEREIN. IF YOU DO NOT AGREE TO
  THESE WEBSITE TERMS, PLEASE DO NOT USE OUR WEBSITE, THE APP, THE
  SERVICES, AND EXIT THIS PAGE IMMEDIATELY. 
</p>
<br />

<p class="font-bold text-[0.85rem]">&nbsp;28. ASSIGNMENT</p>
<p>
  These Website Terms are only for your benefit. You shall have no right
  to assign these Website Terms or any benefits or obligation hereunder to
  any other party or legal entity. Any attempted assignment shall be void
  and would lead to termination of your account. 
</p>
<br />
<p class="font-bold text-[0.85rem]">&nbsp;29. ORDER OF PRECEDENCE</p>

<p>
  To the extent that there is an inconsistency between these Website Terms
  and the terms contained in the
  <span class="underline decoration-gray-500">Shipper Contract</span> or
  the <span class="underline decoration-gray-500">Carrier Contract</span>,
  the
  <span class="underline decoration-gray-500">Shipper Contract</span> and
  the
  <span class="underline decoration-gray-500">Carrier Contract</span> will
  take precedence over the Website Terms, 
</p>
<br />
<p class="font-bold">
  IF YOU ARE A SHIPPER, PLEASE CLICK THROUGH TO THE FOLLOWING LINK AND
  READ AND AGREE TO THE FOLLOWING CONTRACT. 
</p>
<br />
<li>
  <span class="underline decoration-gray-500">Shipper Contract</span>
</li>
<p class="font-bold">
  IF YOU ARE A CARRIER, PLEASE CLICK THROUGH TO THE FOLLOWING LINK AND
  READ AND AGREE TO THE FOLLOWING CONTRACT. 
</p>
<br />
<li>
  <span class="underline decoration-gray-500">Carrier Contract</span>
</li>
</div>
    
    </>
   
  );
};

export default TermsAndConditions;
