import { Grid, Typography } from '@mui/material'
import React from 'react'

const IndustryCard = ({name,text}) => {
  return (
    <Grid item className='w-[100%] px-5 py-5 !flex !flex-col items-center rounded-2xl mt-8 justify-center lg:w-[140px]' sx={{border : "1px solid #FFEEE5"}}
>
        <img src={name} alt="industry" className='!w-[40px] !h-[40px]'/>
        <Typography className='pt-3 !font-bold' sx={{fontFamily : "Proxima Nova"}}>{text}</Typography>
    </Grid>
  )
}

export default IndustryCard