import React from 'react'
import TopCommon from '../components/TopCommon'

const PrivacyPolicy = () => {
  return (
    <>

<TopCommon />

     <div>
        <div class="w-[80%] mx-auto my-10">
    <p class="text-[0.85rem]">&nbsp;ALAUTUN, INC.</p>
    <br />
    <p class="text-center text-[1.3rem]">PRIVACY POLICY</p>
    <br />

    <br />
    <p class="font-bold text-[0.85rem]">&nbsp; INTRODUCTION</p>
    <p>
      Protecting your privacy is really important to us. Accordingly,
      <span class="font-bold"> Alautun, Inc. </span>
      doing business as
      <span class="font-bold">Alautun </span>
      (“Alautun,” “we,” or “us”) is providing this Privacy Policy (the
      “Policy”) to explain our practices regarding our collection, use and
      disclosure of information that we receive when you use our “Platform”
      and “Services” (as defined in the Alautun Terms of Use). Unless we
      define a term in this Privacy Policy, all capitalized words used in this
      Privacy Policy have the same meanings as in our
      <span class="underline decoration-gray-500"> Terms of Use.</span>
    </p>
    <br />

    <p class="font-bold text-[1.2rem]">
      PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND
      PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY
      ACCESSING OR USING OUR WEBSITE, PLATFORM AND SERVICES, YOU AGREE TO
      ACCEPT ALL THE TERMS CONTAINED IN THIS PRIVACY POLICY AND ACKNOWLEDGE
      AND AGREE WITH THE PRACTICES DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH
      THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE OUR
      WEBSITE, PLATFORM AND SERVICES. IF YOU HAVE ANY QUESTIONS REGARDING THIS
      PRIVACY POLICY, PLEASE SEND US AN EMAIL AT PRIVACY@ALAUTUN.COM.
    </p>
    <br />

    <p>This policy describes:</p>
    <br />
    <li>
      The types of information we collect or that you provide when you
      purchase, request, download, install, register with, access, or use the
      Platform and Services.
    </li>
    <li>
      Our practices for collecting, using, maintaining, protecting, and
      disclosing that information.
    </li>
    <br />

    <p>
      This policy applies to information we collect through the Platform and
      our Services, in email, text, and other electronic communications sent
      through or in connection with the Platform and the Services.
    </p>
    <br />
    <p>
      This policy <span class="font-bold"> DOES NOT </span>apply to
      information that you provide to or is collected by any third party (see
      “Third-Party Information Collection”). These third parties may have
      their own privacy policies, which we encourage you to read before
      providing information on or through them.
    </p>
    <br />
    <p class="font-bold text-[1.2rem]">
      IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, DO NOT REQUEST,
      ACCESS, INSTALL, DOWNLOAD, REGISTER WITH, OR USE THE PLATFORM AND THE
      SERVICES. IF YOU DO NOT AGREE WITH THE TERMS OF THIS POLICY, PLEASE DO
      NOT ACCESS AND USE OUR PLATFORM AND SERVICES.
    </p>
    <br />
    <p class="font-bold text-[1.2rem]">
      BY ACCESSING OUR PLATFORM AND SERVICES, YOU AGREE TO ACCEPT, AND BE
      BOUND BY ALL THE TERMS CONTAINED IN THIS POLICY AND ACKNOWLEDGE AND
      AGREE WITH THE PRACTICES DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THE
      TERMS OF THIS POLICY, PLEASE DO NOT USE OR ACCESS OUR PLATFORM AND/OR
      SERVICES.
    </p>
    <br />
    <p>
      All Users of the Platform and the Services will be required to confirm
      they have reviewed and agreed to the terms of this Privacy Policy by
      reviewing and clicking through a checkbox when registering and creating
      an Account on our Platform.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; WHAT INFORMATION DO WE COLLECT?
    </p>
    <p class="font-bold text-[1.2rem]">Information You Give Us.</p>
    <br />
    <p>
      We collect information you provide directly to us. For example, we
      collect information when you fill out a form, request to be a carrier,
      shipper, or driver through the Platform and/or the Services, log into
      your Account, submit or accept a shipment, request customer support, or
      communicate with us. When you create a Alautun Account, contact us, or
      request, access, or use the Platform and the Services, we may ask you to
      provide information. Any information you provide to us will be stored
      and this information includes:
    </p>
    <br />
    <div>
      <li>
        Personal Identifiable Information (“PII”). When you register on our
        Website and create an Account with us we collect information that will
        personally identify you, i.e., your name, address, telephone number,
        social security number, service rating and reviews, photograph,
        employer, job title, or any other identifier.
      </li>
      <br />
      <li>
        Company Information. If the User is a Company or uses our Products and
        Services on behalf of a Company, you will provide information such as
        company name, telephone number, email address and physical address.
      </li>
      <br />
      <li>
        Information from Shippers. If you are a shipper using the Platform and
        the Services and/or you submit a shipment request, we collect details
        related to your submission, such as the date and time of your request,
        pick-up and delivery location and time, cargo description and value,
        rate, identity of shipper and receiver, and other related information
        about the shipment.
      </li>
      <br />
      <li>
        Location Information. When you use our Platform, we collect your
        location information. Location information may include your current
        and expected future location.
      </li>
      <br />
      <li>
        Information from Carriers. If you are a carrier, we collect
        information on your bids on shipments as well as information about
        your acceptance and fulfillment of shipments, including information
        regarding the vehicle, driver, insurance, and other legal items.
      </li>
      <br />
      <li>
        For Drivers. If you are a driver transporting shipments on behalf of a
        carrier, we collect information on your travel to fulfill a shipment
        delivery, your location, legal information (including CDL number, MC
        number,USDOT number, and similar information), and insurance
        information, and other information or files you upload to us.
      </li>
    </div>
    <br />
    <p class="font-bold text-[1.2rem]">
      Automatic Information Collection and Tracking.
    </p>
    <br />
    <p>
      When you use or access the Services or Platform, our technology will
      automatically collect the following:
    </p>
    <br />
    <div>
      <li>
        Usage and Preference Details. When you access and use the Platform and
        the Services, we may automatically collect certain details of your
        access to and use of the Platform and the Services, including,
        location data, logs, communications between Alautun and Users, and
        other communication data and the resources that you access and use on
        or through the Platform and the Services. We collect information about
        how you interact with our Platform, Services, your preferences, and
        settings chosen. We may collect and store any correspondence you have
        with other Users on the Website.
      </li>
      <br />
      <li>
        Device Information. We may collect information about your mobile
        device and internet connection, including the device’s unique device
        identifier, operating system, IP address, type of browser, mobile
        network information, and telephone number.
      </li>
      <br />
      <li>
        Stored Information and Files. The Platform and the Services also may
        access metadata and other information associated with other files
        stored on your device.
      </li>
      <br />
      <li>
        Location Information. Providing our Services to our Users requires the
        collection of location information. Depending on the Services you use,
        we may collect information regarding your precise and/or approximate
        location through the use of GPS, IP address or WIFI.
      </li>
      <br />
    </div>
    <p class="text-[0.85rem] font-bold">
      &nbsp; HOW DO WE COLLECT INFORMATION FROM YOU?
    </p>
    <p>
      We collect information from and about Users of the Platform and Services
      in the following ways:
    </p>
    <br />
    <li>
      When a User directly provides such information to us either by
      registering an account on our Website or answering a
      questionnaire/survey or when requesting customer support services from
      us;
    </li>
    <li>
      Automatically and directly from you or your device when you use the
      Platform and the Services; and
    </li>
    <li>
      From Third Parties, who you allow to collect data from your Services and
      share it with Alautun. We may also receive information about your
      location from third parties, such as shippers, carriers, and drivers
      involved in fulfilling any shipment request.
    </li>

    <br />
    <p class="text-[0.85rem] font-bold">&nbsp; HOW WE USE YOUR INFORMATION</p>
    <p>
      PLEASE NOTE THAT WE DO NOT SELL, TRADE, OR TRANSFER TO OUTSIDE THIRD
      PARTY’S ANY OF ITS USERS INFORMATION.
    </p>
    <br />
    <p>
      Our main goal is to provide you with enhanced experience when using our
      Platform and Services. We use the information that you share with us or
      that we collect from you in the following ways:
    </p>
    <div>
      <li>
        To personalize your experience when you use and access our Platform
        and Services;
      </li>
      <li>
        To provide, maintain, operate and continuously improve our Platform
        and Service offerings;
      </li>
      <li>
        To enable you to easily access and use the Platform and the Services;
      </li>
      <li>
        To provide you information about our other endeavors and modifications
        related to Alautun;
      </li>
      <li>
        To respond to your customer support enquiries and other such related
        questions and concerns;
      </li>
      <li>
        To notify you about your Account and process your registration with
        the Platform and the Services, including verifying whether your
        information is valid.
      </li>
      <li>
        To help maintain the safety, security, and integrity of the Services.
        Information may also be collected from drivers and carrier regarding
        unsafe driving behavior;
      </li>
      <li>
        Fulfill our obligations and enforce our rights arising from any
        contracts entered into between you and us, including the
        <span class="underline decoration-gray-500"> Carrier Contract</span>,
        <span class="underline decoration-gray-500">Shipper Contract</span>,
        or any other agreement for billing and collection. Contact and
        purchase information, and any other necessary information, may be
        shared with financial or billing companies (or similar entities) for
        use in processing payments or invoices.
      </li>
      <li>
        To provide content, features; and/or advertisements that match your
        interests and preferences or otherwise customize your experience on
        our Platform; and
      </li>
      <li>
        To send you periodic emails offering and informing you about our
        additional platforms and other special offers and promotions that we
        think you may be interested in. If you wish to opt out of email
        marketing, please follow the instructions on the bottom of our emails
        or contact us at
        <span class="underline decoration-gray-500">privacy@alautun.com</span>.
      </li>
    </div>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; HOW YOUR INFORMATION COULD BE DISCLOSED/SHARED?
    </p>
    <p>
      Alautun reserves the right to collate non-identifiable anonymous data
      that is gathered from User’s information. We may disclose aggregated
      information about our Users, and information that does not identify any
      individual or device specifically.
    </p>
    <br />
    <p>
      Additionally, we may disclose personal information that we collect, or
      you provide in the following ways:
    </p>
    <br />
    <li>
      We do not sell, trade, rent, or otherwise transfer personally
      identifiable information to others, except: (i) as provided in this
      Policy, (ii) to defend against legal claims or otherwise protect our
      rights, (ii) as required by law, judicial proceeding, court order, or
      legal process served on us, (iv) for purposes of law enforcement
      investigations, (v) to protect rights, property, or safety of our
      customers, and/or (vi) with your consent;
    </li>
    <li>
      We do not collect cookies of any kind from users; however, our ad
      networks and analytics service providers may collect information about
      your use of other websites and online services over time through
      cookies, if those websites and online services also use the same service
      providers. The information collected in this manner may include IP
      address, browser characteristics, device IDs and characteristics,
      operating system version, language preferences, referring URLs, and
      information about the usage of our Platform. We currently use Google
      Analytics to collect and process certain website usage data. To learn
      more about Google Analytics and the methods on how to opt out, please
      visit the following link as listed here,
      <span class="underline decoration-gray-500">www.policies.google.com/privacy/partners</span>;
    </li>
    <li>
      To fulfill the purpose for which you provided us your information;
    </li>
    <li>
      To our parent, subsidiary, and affiliated businesses and partners, for
      legal reasons, or in the event of a dispute;
    </li>
    <li>
      To contractors, service providers, and other third parties providing
      support to us in our business; and/or
    </li>
    <li>
      To a buyer or other successor in the event of a merger, divestiture,
      restructuring, reorganization, dissolution, or other sale or transfer of
      some or all of the Alautun’s assets, whether as a going concern or as
      part of bankruptcy, liquidation, or similar proceeding.
    </li>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp; OUR COOKIE POLICY</p>
    <p>
      Cookies are small pieces of text used to store information on web
      browsers. Cookies are used to store and receive identifiers and other
      information on computers, phones, and other devices. Other technologies,
      including data we store on your web browser or device, identifiers
      associated with your device, and other software, are used for similar
      purposes. In this Policy, we refer to all of these technologies as
      “Cookies.” We use cookies to compile data regarding user interactions
      with ad impressions, and other ad service functions as they relate to
      our Website.
    </p>
    <br />
    <p>
      You can set your browser to refuse all or some browser cookies, or to
      alert you when cookies are being sent. If you disable or refuse cookies,
      please note that some parts of this site may then be inaccessible or not
      function properly.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; ACCESSING AND CORRECTION YOUR INFORMATION
    </p>
    <p>
      You can review and change your personal information by logging into the
      Platform and visiting your account profile page. You can also request us
      to make changes to your PII by sending us an email at
      <span class="underline decoration-gray-500">privacy@alautun.com</span>.
      However, we reserve the right to deny your request if we believe the
      change would violate any applicable law or legal requirement.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; THIRD-PARTY INFORMATION COLLECTION
    </p>
    <p>
      When you use the Platform and the Services, certain third parties may
      use automatic information collection technologies to collect information
      about you or your device. These third parties may include:
    </p>
    <br />
    <li>Advertisers, ad networks, and ad servers.</li>
    <li>Analytics companies.</li>
    <li>Your mobile device manufacturer.</li>
    <li>Your mobile service provider.</li>
    <br />
    <p>
      These third parties may use tracking technologies to collect information
      about you when you use the Platform and the Services. The information
      they collect may be associated with your personal information or they
      may collect information, including personal information, about your
      online activities over time and across different websites, apps, and
      other online services websites. They may use this information to provide
      you with interest-based (behavioral) advertising or other targeted
      content.
    </p>
    <br />
    <p>
      We do not control these third parties’ tracking technologies or how they
      may be used. If you have any questions about an advertisement or other
      targeted content, you should contact the responsible provider directly.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; ANTI- BRIBERY REPRESENTATIONS AND WARRANTIES
    </p>
    <p>
      Alautun and its officers, directors, employees, agents, and anyone
      acting on its behalf (collectively, the “Representatives“) are in
      compliance with all applicable anti-bribery and anti-corruption laws,
      including the US Foreign Corrupt Practices Act and Canadian Corruption
      of Public Officials Act.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp; CHILDREN UNDER 13</p>
    <p>
      The Platform and the Services are not intended for children under 13
      years of age, and we do not knowingly collect personal information from
      children under 13. If we learn we have collected or received personal
      information from a child under 13, we will delete that information. If
      you believe we might have any information about a child under 13, please
      contact us at
      <span class="underline decoration-gray-500">privacy@alautun.com</span>
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp; YOUR CALIFORNIA PRIVACY RIGHTS
    </p>
    <p>
      California Civil Code Section 1798.83 allows Users of the Platform and
      the Services that are California residents to request certain
      information regarding our disclosure of personal information to third
      parties for their direct marketing purposes. To make such a request,
      please send an email to
      <span class="underline decoration-gray-500">privacy@alautun.com</span>.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp;CALIFORNIA CONSUMER PRIVACY ACT
    </p>
    <p>
      This Section supplements the information contained in our Privacy Policy
      above and applies solely to all visitors, users, and others to our
      Website, Platform, or Services, who reside in the State of California
      (“consumers” or “you”). We adopt this Section to comply with the
      California Consumer Privacy Act of 2018 (“CCPA”) and any terms defined
      in the CCPA have the same meaning when used in this Section.
    </p>
    <br />
    <li>
      i. Right to Request Personal Information. Upon request, we will provide
      you with (i) a list of all Personal Information that we have collected
      on you, (ii) from whom we obtained such Personal Information, (iii) the
      reason why we collected such Personal Information, and (iv) with whom
      (if any) we have shared such Personal Information. If we sell your
      Personal Information or disclose your Personal Information to third
      parties, upon request, we will provide you with (i) a list of the
      Personal Information that we have collected on you, (ii) a list of the
      Personal Information that we sell or disclose to others on you, and
      (iii) to whom we have sold or disclosed your Personal Information. A
      consumer can make such a request only twice in a 12-month period.We
      require such Personal Information to be able to provide to you our
      Services. Unless otherwise specified, we only collect Personal
      Information from you. We do not use others to provide us with your
      Personal Information.
    </li>
    <li>
      ii. Disclosure of Personal Information. We only share your Personal
      Information with service providers, e.g., billing and collection agents,
      who enable us to provide our Services to you. We do not sell or give
      your Personal Information to third parties for purposes unrelated to our
      provision of Services to you.
    </li>
    <li>
      iii. Right to have Personal Information Deleted. Upon request, we will
      delete all of your Personal Information that we have collected on you
      and will direct our Service Providers to also delete all of your
      Personal Information. But note that if we do delete all of this Personal
      Information, you will no longer be able to use our Services.
    </li>
    <li>
      iv. Non-Discrimination Right. We will not discriminate against you for
      exercising any of your CCPA rights. Unless permitted by the CCPA, we
      will not:
    </li>

    <li>(i) Deny you goods or services.</li>
    <li>
      (ii) Charge you different prices or rates for goods or services,
      including through granting discounts or other benefits, or imposing
      penalties.
    </li>
    <li>
      (iii) Provide you a different level or quality of goods or services.
    </li>
    <li>
      (iv) Suggest that you may receive a different price or rate for goods or
      services or a different level or quality of goods or services.
    </li>
    <li>
      v. Financial Incentives. However, we may offer you certain financial
      incentives permitted by the CCPA that can result in different prices,
      rates, or quality levels. Any CCPA-permitted financial incentive we
      offer will reasonably relate to your personal information’s value and
      contain written terms that describe the program’s material aspects.
      Participation in a financial incentive program requires your prior opt
      in consent, which you may revoke at any time.
    </li>
    <li>
      vi. Contact Information. You may contact us (i) at privacy@alautun.com,
      (ii) by phone at our toll-free number 1-833-ALAUTUN, or (iii) by writing
      to us at Privacy Officer, at 2524 Buena Vista AVE, Alameda, CA-94501] to
      (i) make a Personal Information Request, (ii) lodge a complaint about
      our use or storage of your Personal Information, (iii) ask us to delete
      such Personal Information, and/or (iv) discuss our Privacy Policy and/or
      anything that has to do with it. We will respond within forty-five (45)
      days of receiving such request or query. Additionally, in order for us
      to respond to your request or query, we will need to collect information
      from the requesting party to verify their identity.
    </li>
    <li>
      vi. Under 16. We will not sell your Personal Information if you are
      under the age of 16 unless we have the consent of your parent or your
      guardian nor will we sell it if you ask us not to do so.
    </li>
    <li>
      vii. Opt Out Right. Upon your request, we will stop selling your
      Personal Information (sometimes called your Opt Out Right). You may send
      the request to Opt Out (i) to privacy@alautun.com, (ii) by phone at our
      toll-free number 1-833-ALAUTUN, or (iii) by writing to us at Privacy
      Officer, 2524 Buena Vista AVE, Alameda, CA 94501.
    </li>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp;FOR OUR CANADIAN USERS</p>
    <p>
      This Section supplements the information contained in our Privacy Policy
      above and applies solely to all visitors, users, and others to our
      Website, Platform or Services, who reside in Canada (“consumers” or
      “you”). We ensure with the Personal Information Protection and
      Electronics Document Act of 2000 (“PIPEDA”) and any terms defined in the
      PIPEDA have the same meaning when used in this Section.
    </p>
    <br />
    <li>
      i. Definition of Personal Information. Any information about an
      identifiable individual. Whatever may be the physical form or
      characteristics of a particular regime for “business contact
      information” (name, position, title, address, professional phone number,
      etc.)
    </li>
    <li>
      ii. Right to Access Personal Information. You can request to access your
      personal information we hold about you. We will first confirm whether
      you have requested such information, explain how we have used your
      information, provide a list of names with whom your information has been
      shared and provide a copy of your information in an accessible format
      and make alternative formats available if requested.
    </li>
    <li>
      iii. Right to Correction/Limited Right to Deletion. You can request us
      to correct or delete your information IF you demonstrate that the
      personal information we hold on you is inaccurate. We will delete or
      correct your information within thirty (30) calendar days. When we
      delete/correct your personal information we will inform the third
      parties with whom we have shared your information.
    </li>
    <li>
      iv. Right to be Forgotten. Your information will be kept with us for as
      long as it is required for the fulfillment of the purposes of Alautun
      platform. Unless we otherwise give you notice, we will retain your
      Information on the Alautun Platform on your behalf until such times as
      you or we terminate your User Account.
    </li>
    <li>
      v. Data Breach Notification. We will send a notification to you as soon
      as feasible regarding the information of any breach that creates a “real
      risk of significant harm” to you. We keep a record of every data breach
      and, on request, provide the Office of the Privacy Commissioner with
      access to the record.
    </li>
    <li>
      vi. Canadian Privacy Officer. We have appointed a Canadian Privacy and
      Data Protection Officer, [Name] privacy@sealink, to make sure the
      privacy rights of our Canadian users are protected in compliance with
      PIPEDA.
    </li>
    <li>
      vii. Two Factor Authentication. You may enable two-factor authentication
      on your account to help ensure that only you can access your account. If
      you do, in addition to entering your password to log in to your account
      to access the Alautun Platform, we will send a code to your mobile
      number, which you will need to enter. This added security prevents
      anyone else from accessing your Alautun account unless they have access
      to your login information.
    </li>
    <li>
      viii. Contact Information. You may contact us (i) at
      privacy@alautun.com, or (ii) by writing to us at Privacy Officer, at
      2524 Buena Vista AVE, Alameda, CA 94501 to (i) make a Personal
      Information Request, (ii) correct or delete your personal information,
      (iii) discuss our Privacy Policy and/or anything that has to do with it.
      We will respond within thirty (30) calendar days of receiving such a
      request or query. Additionally, in order for us to respond to your
      request or query, we will need to collect information from the
      requesting party to verify their identity.
    </li>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp;DATA SECURITY</p>
    <p>
      We have implemented measures to help secure your personal information
      from accidental loss and from unauthorized access, use, alteration, and
      disclosure. All information you provide to us is stored on our secure
      servers behind firewalls. Any online payment transactions will be
      encrypted using SSL technology. Payment transactions may also be handled
      by third parties. Alautun will use commercially reasonable efforts to
      work with financial and billing companies (or similar entities) with
      reasonable and acceptable security measures.
    </p>
    <p>
      The security of your PII also depends on you. You are responsible for
      keeping your password for the Platform and Services confidential. Avoid
      sharing your password with others or giving out information in public
      areas related to the Platform and the Services. Publicly shared
      information about or relating to your account may weaken the strength of
      your Account’s security.
    </p>
    <p>
      No security measures are perfect or impenetrable. The transmission of
      information via the internet and mobile applications or platforms is not
      completely secure. We cannot control the actions of third parties with
      whom you may choose to share your account information. Alautun cannot
      guarantee the security of your personal information transmitted through
      the Services. The transmission of personal information is at your own
      risk. We are not responsible for circumvention of any privacy settings
      or security measures we provide. In the unlikely event of a data
      security breach, Alautun will notify you of the breach.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp;INTERNATIONAL USE</p>
    <p>
      Our Platform and Services are currently only available in the United
      States and are intended for use in the United States. Do not register
      for or use our Services or access our Platform if you reside in Europe.
      If you register for or use the Platform and the Services from other
      countries, they have different governing laws with respect to the
      processing, transporting and storage of personal data and you must
      comply with all such laws with respect to such transport, storage and
      processing. Please note that you are transferring your PII from that
      country to the United States for storage and processing. By providing
      any information, including PII, to us, you consent to such transfer,
      storage, and processing and will defend, indemnify and hold us harmless
      from any and all costs and damages associated with such transfer,
      storage and process of such personal data.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">
      &nbsp;CHANGES TO OUR PRIVACY POLICY
    </p>
    <p>
      Alautun reserves the right, at its sole discretion, to change or modify
      this Policy at any time. In the event, we modify this Policy, such
      modifications shall be binding on you only upon your acceptance of the
      modified Policy. We will inform you about the modifications via email or
      comparable means within 15 days of such modification. We will also post
      the modified version on this page. Your continued use of the Platform
      and the Services shall constitute your consent to such changes. Alautun
      may change, modify, suspend, or discontinue any aspect of the Platform
      and/or Services at any time without notice or liability.
    </p>
    <br />
    <p class="text-[0.85rem] font-bold">&nbsp; CONTACTING US</p>
    <p>
      To ask questions or comment about this Policy and our privacy practices,
      contact us at:
    </p>
    <br />
    <p class="text-[1.2rem]">Privacy Officer</p>
    <br />
    <p class="text-[1.2rem]">
      Email:
      <span class="underline decoration-gray-500"> privacy@alautun.com</span>
    </p>
    <br />
    <p class="text-[1.2rem]">
      Address: 2524 Buena Vista AVE, Alameda, CA 94501.
    </p>
    <br />
    <p class="font-bold text-[1.2rem]">
      PLEASE NOTE: IF YOU USE OUR PLATFORM AND/OR SERVICES, YOU HAVE AGREED TO
      AND ACCEPTED THE PRACTICES DESCRIBED IN THIS POLICY AND THE TERMS AND
      CONDITIONS SET FORTH IN OUR
      <span class="underline decoration-gray-500"> TERMS OF USE</span>. IF YOU
      DO NOT AGREE WITH THE TERMS OF THIS POLICY OR THE
      <span class="underline decoration-gray-500"> TERMS OF USE</span>, PLEASE
      DO NOT ACCESS OR USE OUR PLATFORM AND/OR SERVICES.
    </p>
  </div>
    </div>
    </>
   
  )
}

export default PrivacyPolicy
